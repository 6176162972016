export enum CalendarProviderType {
  GOOGLE = "google",
  MICROSOFT = "microsoft"
}

export interface ICalendarAccount {
  userEmail: string;
  userDisplayName: string;
  id: string;
  type: CalendarProviderType;
}
