import { isSameDay, isSameMonth, isWithinRange } from "date-fns";
import * as React from "react";
import { ThemeColors } from "../styles";
import { areListsEqual, IAppointment } from "../models/IAppointment";
import { getDaysInMonth, logError, toYearMonthString } from "../utils";
import MonthDayNumberOnly from "./MonthDayNumberOnly";
import MonthDayWithAppointments from "./MonthDayWithAppointments";

interface IComponentState {}

interface IComponentProps {
  monthDate: Date;
  appointments?: IAppointment[];
  inNoAppointmentsMode?: boolean;
}

const monthGridContainerStyle: React.CSSProperties = {
  height: "100%",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
  gridAutoRows: "1fr",
  gap: "1px",
  gridGap:
    "1px" /* Safari uses grid-gap even though standard says to use gap */,
  backgroundColor: ThemeColors.SecondaryBackground
};

const monthDayContainerStyle: React.CSSProperties = {
  minWidth: 0,
  backgroundColor: ThemeColors.PrimaryBackground
};

export default class MonthGrid extends React.Component<
  IComponentProps,
  IComponentState
> {
  private _monthString: string | null = null;
  private get monthString(): string {
    if (!this._monthString) {
      this._monthString = toYearMonthString(this.props.monthDate);
    }
    return this._monthString;
  }

  constructor(props: IComponentProps) {
    super(props);

    this.state = {};
  }

  public shouldComponentUpdate(
    newProps: IComponentProps,
    newState: IComponentState
  ) {
    if (!isSameMonth(this.props.monthDate, newProps.monthDate)) {
      return true;
    }

    if (!areListsEqual(this.props.appointments, newProps.appointments)) {
      return true;
    }

    if (this.props.inNoAppointmentsMode !== newProps.inNoAppointmentsMode) {
      return true;
    }

    return false;
  }

  public render() {
    const days = getDaysInMonth(
      this.props.monthDate.getFullYear(),
      this.props.monthDate.getMonth() + 1
    );

    return (
      <div style={monthGridContainerStyle}>{days.map(this.renderDay)}</div>
    );
  }

  private renderDay = (day: Date, index: number, array: Date[]) => {
    const theKey = `daycell-${this.monthString}-${index}`;

    if (this.props.inNoAppointmentsMode) {
      return (
        <div style={monthDayContainerStyle} key={theKey}>
          <MonthDayNumberOnly
            key={theKey}
            date={day}
            partOfOtherMonth={
              this.props.monthDate.getMonth() !== day.getMonth()
            }
          />
        </div>
      );
    }

    const filteredAppointments =
      this.props.appointments &&
      this.props.appointments.filter(a => {
        const end = a.StartTime === a.EndTime ? a.EndTime : a.EndTime - 1;

        if (isSameDay(day, a.StartTime) || isSameDay(day, end)) {
          return true;
        }

        if (end < a.StartTime) {
          logError("EndDate is before StartDate");
          // I guess include the appointment anyway
          return isWithinRange(day, end, a.StartTime);
        }

        return isWithinRange(day, a.StartTime, end);
      });

    return (
      <div style={monthDayContainerStyle} key={theKey}>
        <MonthDayWithAppointments
          key={theKey}
          date={day}
          appointments={filteredAppointments}
          partOfOtherMonth={this.props.monthDate.getMonth() !== day.getMonth()}
        />
      </div>
    );
  };
}
