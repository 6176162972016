import { MonthDayHoverContent } from "./MonthDayHoverContent";
import { isSameDay, isToday } from "date-fns";
import * as React from "react";
import { ThemeColors } from "../styles";
import { toYearMonthDayString } from "../utils";
import {
  ISelectedDateContext,
  SelectedDateContext
} from "../contexts/selectedDate-context";
import { areListsEqual, IAppointment } from "../models/IAppointment";

interface IComponentState {}

interface IComponentProps {
  date: Date;
  selectedDateContext: ISelectedDateContext;
  appointments?: IAppointment[];
  partOfOtherMonth?: boolean;
}

const monthDayContainerStyle: React.CSSProperties = {
  height: "100%",
  backgroundColor: ThemeColors.PrimaryBackground,
  color: ThemeColors.AccentForeground,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  alignContent: "flex-start"
};

const dateRowStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
  fontSize: "calc(40% + 0.2vw + 0.9vh)",
  padding: "5px",
  userSelect: "none"
};

const bodyRowStyle: React.CSSProperties = {
  flex: 1,
  alignSelf: "stretch",
  fontSize: "calc(25% + 0.6vw + 0.2vh)",
  lineHeight: "calc(25% + 0.5vw + 0.5vh)",
  color: ThemeColors.PrimaryForeground,
  paddingLeft: "5px",
  overflowY: "hidden"
};

const appointmentRowStyle: React.CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
};

// const FooterRow = styled.div`
//   align-self: stretch;
//   font-size: calc(15% + 0.6vw + 0.2vh);
//   padding: 5px;
//   user-select: none;

//   :hover {
//     cursor: default;
//   }
// `;

class MonthDayWithAppointments extends React.Component<
  IComponentProps,
  IComponentState
> {
  private _todayString: string | null = null;
  private get todayString(): string {
    if (!this._todayString) {
      this._todayString = toYearMonthDayString(this.props.date);
    }
    return this._todayString;
  }

  constructor(props: IComponentProps) {
    super(props);

    this.state = { isHovered: false };
  }

  public shouldComponentUpdate(
    newProps: IComponentProps,
    newState: IComponentState
  ) {
    if (!isSameDay(this.props.date, newProps.date)) {
      return true;
    }

    if (
      !isSameDay(
        this.props.selectedDateContext.today,
        newProps.selectedDateContext.today
      )
    ) {
      return true;
    }

    const wasSelected = isSameDay(
      this.props.date,
      this.props.selectedDateContext.selectedDate
    );
    const willBeSelected = isSameDay(
      newProps.date,
      newProps.selectedDateContext.selectedDate
    );
    if (wasSelected !== willBeSelected) {
      return true;
    }

    if (!areListsEqual(this.props.appointments, newProps.appointments)) {
      return true;
    }

    return false;
  }

  public render() {
    // make sure we are always calculating the proper date string
    this._todayString = null;

    const isThisToday = isToday(this.props.date);

    const isSelected = isSameDay(
      this.props.selectedDateContext.selectedDate,
      this.props.date
    );

    const additionalCellStyle: React.CSSProperties = {
      ...monthDayContainerStyle
    };

    const dateRowStyleWithOpacity = {
      ...dateRowStyle,
      opacity: this.props.partOfOtherMonth ? 0.5 : 1.0
    };

    const bodyRowStyleWithOpacity = {
      ...bodyRowStyle,
      opacity: this.props.partOfOtherMonth ? 0.5 : 1.0
    };

    if (isSelected) {
      additionalCellStyle.boxShadow = `inset 0px 0px 0px 2px ${
        ThemeColors.AccentForeground
      }`;
    }

    if (isThisToday) {
      additionalCellStyle.backgroundColor = ThemeColors.AccentForeground;
      additionalCellStyle.color = ThemeColors.PrimaryForeground;
    }

    return (
      <div
        className="month-day-container no-select"
        style={additionalCellStyle}
        onClick={this.handleClick}
      >
        <div className="month-day-date-row" style={dateRowStyleWithOpacity}>
          <div>{this.props.date.getUTCDate()}</div>
          {(isThisToday || isSelected) && (
            <MonthDayHoverContent date={this.props.date} />
          )}
        </div>
        <div style={bodyRowStyleWithOpacity}>
          {this.props.appointments &&
            this.props.appointments.map(this.renderAppointmentRow)}
        </div>
        {/* <FooterRow
          style={{ opacity: this.props.partOfOtherMonth ? 0.5 : 1.0 }}
        /> */}
      </div>
    );
  }

  private renderAppointmentRow = (
    appointment: IAppointment,
    index: number,
    array: IAppointment[]
  ) => {
    return (
      <div style={appointmentRowStyle} key={`apt-${this.todayString}-${index}`}>
        {appointment.Title}
      </div>
    );
  };

  private selectDate = (date: Date) => {
    this.props.selectedDateContext.selectDate(date);
  };

  private handleClick = event => {
    this.selectDate(this.props.date);
  };
}

export default props => (
  <SelectedDateContext.Consumer>
    {context => (
      <MonthDayWithAppointments {...props} selectedDateContext={context} />
    )}
  </SelectedDateContext.Consumer>
);
