import { Client } from "@microsoft/microsoft-graph-client";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { UserAgentApplication } from "msal";
import { ICalendar } from "../../../models/ICalendar";
import { ICalendarProvider } from "./ICalendarProvider";
import {
  ICalendarAccount,
  CalendarProviderType
} from "../../../models/ICalendarAccount";
import { IAppointment } from "../../../models/IAppointment";

const clientId = "e59598a1-891c-43c0-8e52-8bf6fc848942";
const graphScopes = ["user.read", "calendars.read"];

// An Optional options for initializing the MSAL @see https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/MSAL-basics#configuration-options
// look at this for auth stuff - https://github.com/microsoftgraph/msgraph-training-reactspa

const userAgentApplication = new UserAgentApplication(clientId, null, token => {
  // TODO: figure out if I need to do anything here
  console.log("got the token back");
});

let _client: Client | null = null;

async function login(): Promise<void> {
  await userAgentApplication.loginPopup(graphScopes);
}

async function refreshToken(): Promise<boolean> {
  if (!_client) {
    try {
      const token = await userAgentApplication.acquireTokenSilent(graphScopes);
      if (token) {
        _client = Client.init({
          authProvider: done => {
            done(null, token);
          }
        });
      } else {
        _client = null;
        return false;
      }
    } catch (err) {
      // TODO: inspect error and see if we can do better
      if (err) {
        console.error(err);
      }

      _client = null;
      return false;
    }
  }

  return true;
}

async function ensureLoggedIn(): Promise<boolean> {
  if (!(await refreshToken())) {
    await login();
    return await refreshToken();
  }

  return true;
}

async function getCalendarAccountInfo(): Promise<ICalendarAccount> {
  if (!(await ensureLoggedIn())) {
    // TODO - pass error type so UI can show helpful message
    throw "failed to ensure connection";
  }

  // TODO - error handling
  let userData: MicrosoftGraph.User = await _client!.api("/me").get();
  return <ICalendarAccount>{
    id: userData.id,
    type: CalendarProviderType.MICROSOFT,
    userDisplayName: userData.displayName,
    userEmail: userData.mail
  };
}

async function getCalendars(): Promise<ICalendar[]> {
  if (!(await ensureLoggedIn())) {
    // TODO - pass error type so UI can show helpful message
    throw "failed to ensure connection";
  }

  // TODO - error handling
  const response = await _client!.api("/me/calendars").get();

  const msCalendars = response.value as MicrosoftGraph.Calendar[];

  // TODO - convert from MSA cal color to hex string
  // TODO - wire up IsDisabled
  const calendars = msCalendars.map(
    c =>
      <ICalendar>{
        AccountName: "Outlook", // TODO: put account email on this if you support multi-account
        Id: c.id,
        Color: c.color,
        Hash: c.changeKey,
        IsDisabled: false,
        Title: c.name
      }
  );

  return calendars;
}

async function getAppointmentForCalendar(
  calendar: ICalendar,
  start: Date,
  end: Date
): Promise<IAppointment[]> {
  var response = await _client!
    .api(
      `me/calendars/${
        calendar.Id
      }/calendarview?startdatetime=${start.toISOString()}&enddatetime=${end.toISOString()}`
    )
    .get();

  // TODO: if there is a next link then call again

  const msAppointments = response.value as MicrosoftGraph.Event[];
  const appointments: IAppointment[] = msAppointments.map(
    a =>
      <IAppointment>{
        CalendarId: calendar.Id,
        Id: a.id,
        Title: a.subject,
        StartTime: new Date(a.start!.dateTime!).getTime(),
        EndTime: new Date(a.end!.dateTime!).getTime(),
        Hash: a.changeKey,
        IsAllDay: a.isAllDay,
        Location: a.location && a.location.displayName // TODO: can probably do better with location
      }
  );

  return appointments;
}

async function getAppointments(
  start: Date,
  end: Date
): Promise<IAppointment[]> {
  if (end < start) {
    throw "end Date is before start Date";
  }

  if (!(await ensureLoggedIn())) {
    // TODO - pass error type so UI can show helpful message
    throw "failed to ensure connection";
  }

  const cals = await getCalendars();

  const appointmentsForAllCalendars = await Promise.all(
    cals.map(c => getAppointmentForCalendar(c, start, end))
  );

  let appointments: IAppointment[] = [];
  appointmentsForAllCalendars.forEach(afc => {
    appointments = appointments.concat(afc);
  });

  return appointments;
}

export default <ICalendarProvider>{
  ensureLoggedIn,
  getCalendarAccountInfo,
  getCalendars,
  getAppointments
};

// endpoint for getting week's worth of appts - me/calendarview?startdatetime=2019-03-30T16:25:49.921Z&enddatetime=2019-04-06T16:25:49.921Z

// sample app code from https://docs.microsoft.com/en-us/azure/active-directory/develop/quickstart-v2-javascript
