export interface IAppointment {
  Id: string;
  Hash: string;
  CalendarId: string;
  Title: string;
  Location: string;
  IsAllDay: boolean;

  /**
   * Unix time in milliseconds
   *
   * @type {number}
   * @memberof IAppointment
   */
  StartTime: number;

  /**
   * Unix time in milliseconds
   *
   * @type {number}
   * @memberof IAppointment
   */
  EndTime: number; // unix time in milliseconds
}

/**
 * Checks if two IAppointment objects are equal by checking their Hash values
 *
 * @export
 * @param {IAppointment} appointmentA
 * @param {IAppointment} appointmentB
 * @returns {boolean}
 */
export function areEqual(
  appointmentA: IAppointment,
  appointmentB: IAppointment
): boolean {
  return appointmentA.Hash === appointmentB.Hash;
}

/**
 * Checks if two IAppointment arrays contain the same IAppointment objects
 * in the same order.
 *
 * @export
 * @param {IAppointment[]} listA
 * @param {IAppointment[]} listB
 * @returns
 */
export function areListsEqual(
  listA: IAppointment[] | undefined,
  listB: IAppointment[] | undefined
) {
  // if both undefined then consider them equal
  if (!listA && !listB) {
    return true;
  }

  // if just one is undefined then not equal
  if (!listA || !listB) {
    return false;
  }

  if (listA.length !== listB.length) {
    return false;
  }

  // tslint:disable-next-line:prefer-for-of
  for (let index = 0; index < listA.length; index += 1) {
    if (!areEqual(listA[index], listB[index])) {
      return false;
    }
  }

  return true;
}

export function appointmentComparer(a, b) {
  if (a.StartTime < b.StartTime) {
    return -1;
  }

  if (a.StartTime > b.StartTime) {
    return 1;
  }

  if (a.EndTime < b.EndTime) {
    return -1;
  }

  if (a.EndTime > b.EndTime) {
    return 1;
  }

  return 0;
}
