import { ICalendar } from "../../models/ICalendar";
import MicrosoftCalendarProvider from "./providers/MicrosoftCalendarProvider";
import { IAppointment } from "../../models/IAppointment";
import { addDays } from "date-fns";
import IMonthAppointmentsPayload from "../../models/IMonthAppointmetsPayload";

export async function getCalendars(): Promise<ICalendar[]> {
  const cals = await MicrosoftCalendarProvider.getCalendars();

  return cals;
}

export async function getAppointments(
  start: Date,
  end: Date
): Promise<IAppointment[]> {
  const appointments = await MicrosoftCalendarProvider.getAppointments(
    start,
    end
  );

  return appointments;
}

export async function getAppointmentsForMonth(
  year: number,
  month: number
): Promise<IMonthAppointmentsPayload> {
  let date = new Date(year, month, 1);

  // get appointments starting 7 days before the first of the month
  const startDate = addDays(date, -7);
  // get appointments stopping about 7 days after the end of the month (some
  // months aren't fully 31 days but that's fine)
  const endDate = addDays(date, 37);

  const appointments = await MicrosoftCalendarProvider.getAppointments(
    startDate,
    endDate
  );

  const calendars = await getCalendars();

  let hash = "::calendars::";
  hash += calendars.map(c => c.Hash).join("::cal-delim::");
  hash += "::appointments::";
  hash += appointments.map(a => a.Hash).join("::appt-delim::");

  const safeMonth = `0${month}`.slice(-2);

  return {
    Appointments: appointments,
    YearMonthString: `${year}-${safeMonth}`,
    Calendars: calendars,
    Hash: hash
  };
}
