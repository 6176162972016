import {
  isWithinRange,
  isSameMonth,
  isSameDay,
  addMonths,
  addDays,
  differenceInMinutes
} from "date-fns";
import * as React from "react";
import { HotKeys } from "react-hotkeys";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AlertsContext, IAlertsContext } from "../contexts/alerts-context";
import {
  CalendarsContext,
  ICalendarsContext
} from "../contexts/calendars-context";
import {
  ISelectedDateContext,
  SelectedDateContext
} from "../contexts/selectedDate-context";
import IMonthAppointmentsPayload from "../models/IMonthAppointmetsPayload";
import INativeEventPayload from "../models/INativeEventPayload";
import { PermissionStatus } from "../models/PermissionStatus";
import { ThemeColors } from "../styles";
import {
  getIsInMacWebview,
  InteropConstants,
  log,
  logError,
  QueryStringNames,
  readDateFromURL,
  toYearMonthString,
  setMidnightInterval,
  stopMidnightIntervalTimer
} from "../utils";
import AlertBannerContainer from "./Alerts/AlertBannerContainer";
import AppointmentList from "./AppointmentList/AppointmentList";
import LeftDrawer from "./LeftDrawer";
import { MonthDayOfWeekRow } from "./MonthDayOfWeekRow";
import MonthFooter from "./MonthFooter";
import MonthGrid from "./MonthGrid";
import MonthHeader from "./MonthHeader";
import "infinite-carousel-wc";
import "side-drawer";
import { InfiniteCarouselWc } from "infinite-carousel-wc";
import { SideDrawer } from "side-drawer";
import { getAppointmentsForMonth } from "../services/calendar/CalendarService";

interface IComponentState {
  isDrawerOpen: boolean;
  inNoAppointmentsMode: boolean;
  areAllCalendarsDisabled: boolean;
  appointmentsMap: Map<string, IMonthAppointmentsPayload>;
  selectedDateContext: ISelectedDateContext;
  alertsContext: IAlertsContext;
  calendarsContext: ICalendarsContext;
  calendarPermissionStatus?: PermissionStatus;
  slot1Date: Date;
  slot2Date: Date;
  slot3Date: Date;
}

interface IComponentProps {}

type ICombinedProps = IComponentProps & RouteComponentProps<{}>;

class MonthCalendar extends React.Component<ICombinedProps, IComponentState> {
  private _popoverClosedTime?: Date;
  private _carouselRef: React.RefObject<InfiniteCarouselWc>;
  private _sideDrawerRef: React.RefObject<SideDrawer>;

  private keyMap = {
    goLeft: "left",
    goRight: "right",
    goUp: "up",
    goDown: "down",
    today: "t",
    home: "home",
    end: "end"
  };

  private handlers = {
    goLeft: event => this.handleSelectedDayChanged(-1),
    goRight: event => this.handleSelectedDayChanged(1),
    goUp: event => this.handleSelectedDayChanged(-7),
    goDown: event => this.handleSelectedDayChanged(7),
    today: event => this.goToToday(),
    end: event => this.handleNextMonthClicked(),
    home: event => this.handlePreviousMonthClicked()
  };

  private noAppointmentModeHandlers = {
    goLeft: event => this.handlePreviousMonthClicked(),
    goRight: event => this.handleNextMonthClicked(),
    today: event => this.goToToday(),
    end: event => this.handleNextMonthClicked(),
    home: event => this.handlePreviousMonthClicked()
  };

  private get currentDisplayDate(): Date {
    return readDateFromURL(
      new URLSearchParams(this.props.location.search),
      QueryStringNames.DISPLAYED_DATE
    );
  }

  constructor(props: ICombinedProps) {
    super(props);
    this._carouselRef = React.createRef();
    this._sideDrawerRef = React.createRef();

    this.state = {
      inNoAppointmentsMode: true,
      areAllCalendarsDisabled: false,
      isDrawerOpen: false,
      appointmentsMap: new Map(),
      selectedDateContext: {
        today: new Date(),
        selectedDate: new Date(),
        selectDate: this.handleSelectDate
      },
      alertsContext: {
        enableShowAppointmentsAlert: false,
        showAppointmentsAlertResult: this.handleShowAppointmentsAlertResult,
        enableShowAppointmentsDrawerAlert: false,
        showAppointmentsDrawerAlertResult: this
          .handleShowAppointmentsDrawerAlertResult,
        enableNoCalendarsDrawerAlert: false
      },
      calendarsContext: {
        calendars: [],
        refreshCalendars: this.fetchCurrentMonthAppointments
      },
      calendarPermissionStatus: undefined,
      slot1Date: new Date(),
      slot2Date: addMonths(new Date(), 1),
      slot3Date: addMonths(new Date(), -1)
    };

    // make sure to update the current day at midnight
    setMidnightInterval(() => {
      log(
        `midnight timer hit at ${new Date()}, updating this.state.selectedDateContext.today`
      );
      this.setState(state => ({
        ...state,
        selectedDateContext: { ...state.selectedDateContext, today: new Date() }
      }));
    });

    if (getIsInMacWebview()) {
      window.addEventListener(
        InteropConstants.NATIVE_EVENT_EVENTNAME,
        this.handleEventFromNative
      );

      window.addEventListener(
        InteropConstants.NATIVE_EVENT_WITH_DATA_EVENTNAME,
        this.handleEventWithDataFromNative
      );

      // check calendar permissions, the event back from this will initiate
      // appointment retrieval
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        InteropConstants.JS_MESSAGE_GET_CALENDAR_PERMISSION_STATUS
      );
    } else {
      // we are in web and load mock data

      const currentMonth = readDateFromURL(
        new URLSearchParams(props.location.search),
        QueryStringNames.DISPLAYED_DATE
      );

      getAppointmentsForMonth(
        currentMonth.getFullYear(),
        currentMonth.getMonth()
      ).then(this.handleMonthAppointmentPayloadResponse);

      this.primeOtherMonths();
    }
  }

  public shouldComponentUpdate(
    nextProps: ICombinedProps,
    nextState: IComponentState
  ): boolean {
    const newDate = readDateFromURL(
      new URLSearchParams(nextProps.location.search),
      QueryStringNames.DISPLAYED_DATE
    );

    if (!isSameMonth(newDate, this.currentDisplayDate)) {
      // kick off appointment retrieval
      if (getIsInMacWebview()) {
        if (nextState.calendarPermissionStatus === PermissionStatus.Allowed) {
          (window as any).webkit.messageHandlers.scriptHandler.postMessage(
            `${InteropConstants.JS_MESSAGE_GETMONTHAPPOINTMENTS}${
              InteropConstants.MESSAGE_DELIMITER
            }${toYearMonthString(newDate)}`
          );
        }
      } else {
        getAppointmentsForMonth(newDate.getFullYear(), newDate.getMonth()).then(
          this.handleMonthAppointmentPayloadResponse
        );
        // TODO: need to have web and webviews follow same code path and get
        // rid of this one-off stuff
        this.primeOtherMonths();
      }
    }

    // return true to tell react to go ahead with the re-render
    return true;
  }

  public async componentDidMount() {
    if (this._carouselRef.current) {
      this._carouselRef.current.addEventListener(
        "next",
        this.handleCarouselNext
      );
      this._carouselRef.current.addEventListener(
        "previous",
        this.handleCarouselPrevious
      );
    }

    if (this._sideDrawerRef.current) {
      this._sideDrawerRef.current.addEventListener(
        "open",
        this.handleDrawerOpened
      );
      this._sideDrawerRef.current.addEventListener(
        "close",
        this.handleDrawerClosed
      );
    }

    // await this.state.calendarsContext.refreshCalendars();
  }

  public componentWillUnmount() {
    stopMidnightIntervalTimer();

    if (getIsInMacWebview()) {
      window.removeEventListener(
        InteropConstants.NATIVE_EVENT_EVENTNAME,
        this.handleEventFromNative
      );
    }

    if (this._carouselRef.current) {
      this._carouselRef.current.removeEventListener(
        "next",
        this.handleCarouselNext
      );
      this._carouselRef.current.removeEventListener(
        "previous",
        this.handleCarouselPrevious
      );
    }

    if (this._sideDrawerRef.current) {
      this._sideDrawerRef.current.removeEventListener(
        "open",
        this.handleDrawerOpened
      );
      this._sideDrawerRef.current.removeEventListener(
        "close",
        this.handleDrawerClosed
      );
    }
  }

  public render() {
    const date = this.currentDisplayDate;

    const slot1AppointmentsPayload = this.state.appointmentsMap.get(
      toYearMonthString(this.state.slot1Date)
    );
    const slot1Appointments =
      slot1AppointmentsPayload && slot1AppointmentsPayload.Appointments;
    const slot2AppointmentsPayload = this.state.appointmentsMap.get(
      toYearMonthString(this.state.slot2Date)
    );
    const slot2Appointments =
      slot2AppointmentsPayload && slot2AppointmentsPayload.Appointments;
    const slot3AppointmentsPayload = this.state.appointmentsMap.get(
      toYearMonthString(this.state.slot3Date)
    );
    const slot3Appointments =
      slot3AppointmentsPayload && slot3AppointmentsPayload.Appointments;

    const selectedDateMonthAppointments = this.state.appointmentsMap.get(
      toYearMonthString(this.state.selectedDateContext.selectedDate)
    );
    const selectedDateAppointments =
      selectedDateMonthAppointments &&
      selectedDateMonthAppointments.Appointments.filter(a => {
        const end = a.StartTime === a.EndTime ? a.EndTime : a.EndTime - 1;

        if (
          isSameDay(this.state.selectedDateContext.selectedDate, a.StartTime) ||
          isSameDay(this.state.selectedDateContext.selectedDate, end)
        ) {
          return true;
        }

        if (end < a.StartTime) {
          logError("EndDate is before StartDate");
          // I guess include the appointment anyway
          return isWithinRange(
            this.state.selectedDateContext.selectedDate,
            end,
            a.StartTime
          );
        }

        return isWithinRange(
          this.state.selectedDateContext.selectedDate,
          a.StartTime,
          end
        );
      });

    const noAppointments =
      this.state.inNoAppointmentsMode || this.state.areAllCalendarsDisabled;

    return (
      <HotKeys
        keyMap={this.keyMap}
        handlers={
          noAppointments ? this.noAppointmentModeHandlers : this.handlers
        }
        style={{ height: "100%" }}
        attach={window}
        focused
      >
        <div className="month-calendar-container">
          <div className="month-calendar-row-header">
            <MonthHeader
              year={date.getFullYear()}
              month={date.getMonth()}
              onDateClick={this.goToToday}
              onNextClick={this.handleNextMonthClicked}
              onPreviousClick={this.handlePreviousMonthClicked}
            />
          </div>
          <div className="month-calendar-row-week-names">
            <MonthDayOfWeekRow />
          </div>
          <div className="month-calendar-row-month-grid">
            <SelectedDateContext.Provider
              value={this.state.selectedDateContext}
            >
              <infinite-carousel-wc
                style={{ height: "100%" }}
                ref={this._carouselRef}
              >
                <div slot="1">
                  <MonthGrid
                    monthDate={this.state.slot1Date}
                    appointments={slot1Appointments}
                    inNoAppointmentsMode={noAppointments}
                  />
                </div>
                <div slot="2">
                  <MonthGrid
                    monthDate={this.state.slot2Date}
                    appointments={slot2Appointments}
                    inNoAppointmentsMode={noAppointments}
                  />
                </div>
                <div slot="3">
                  <MonthGrid
                    monthDate={this.state.slot3Date}
                    appointments={slot3Appointments}
                    inNoAppointmentsMode={noAppointments}
                  />
                </div>
              </infinite-carousel-wc>
            </SelectedDateContext.Provider>
          </div>
          {noAppointments && (
            <div
              style={{
                height: "1px",
                backgroundColor: ThemeColors.SecondaryBackground
              }}
            />
          )}
          {!noAppointments && (
            <div className="month-calendar-row-appointment-list">
              <CalendarsContext.Provider value={this.state.calendarsContext}>
                <AppointmentList
                  date={this.state.selectedDateContext.selectedDate}
                  appointments={selectedDateAppointments}
                />
              </CalendarsContext.Provider>
            </div>
          )}
          <div className="month-calendar-row-alert-banner">
            <AlertsContext.Provider value={this.state.alertsContext}>
              <AlertBannerContainer />
            </AlertsContext.Provider>
          </div>
          <div className="month-calendar-row-footer">
            <MonthFooter
              onMenuClick={this.handleMenuClick}
              onTodayClick={this.goToToday}
            />
          </div>
          <side-drawer
            open={this.state.isDrawerOpen || undefined}
            ref={this._sideDrawerRef}
            style={{ width: "unset" }}
          >
            <CalendarsContext.Provider value={this.state.calendarsContext}>
              <AlertsContext.Provider value={this.state.alertsContext}>
                <LeftDrawer onClose={this.handleDrawerClosed} />
              </AlertsContext.Provider>
            </CalendarsContext.Provider>
          </side-drawer>
        </div>
      </HotKeys>
    );
  }

  private handleDrawerOpened = () => {
    this.setState(state => ({ ...state, isDrawerOpen: true }));
  };

  private handleDrawerClosed = () => {
    this.setState(state => ({ ...state, isDrawerOpen: false }));
  };

  private handleCarouselNext = evt => {
    this.displayDate(
      ((evt.detail.newCurrent === 1 && this.state.slot1Date) ||
        (evt.detail.newCurrent === 2 && this.state.slot2Date) ||
        (evt.detail.newCurrent === 3 && this.state.slot3Date)) as Date,
      true
    );
    return this.setState(state => {
      switch (evt.detail.newCurrent) {
        case 1:
          return { ...state, slot2Date: addMonths(state.slot1Date, 1) };
        case 2:
          return { ...state, slot3Date: addMonths(state.slot2Date, 1) };
        case 3:
          return { ...state, slot1Date: addMonths(state.slot3Date, 1) };
      }
      return null;
    });
  };

  private handleCarouselPrevious = evt => {
    this.displayDate(
      ((evt.detail.newCurrent === 1 && this.state.slot1Date) ||
        (evt.detail.newCurrent === 2 && this.state.slot2Date) ||
        (evt.detail.newCurrent === 3 && this.state.slot3Date)) as Date,
      true
    );
    return this.setState(state => {
      switch (evt.detail.newCurrent) {
        case 1:
          return { ...state, slot3Date: addMonths(state.slot1Date, -1) };
        case 2:
          return { ...state, slot1Date: addMonths(state.slot2Date, -1) };
        case 3:
          return { ...state, slot2Date: addMonths(state.slot3Date, -1) };
      }
      return null;
    });
  };

  private handleSelectDate = (date: Date) => {
    if (!isSameDay(this.state.selectedDateContext.selectedDate, date)) {
      this.setState(state => ({
        ...state,
        selectedDateContext: {
          ...state.selectedDateContext,
          selectedDate: date
        }
      }));
    }
  };

  private handleShowAppointmentsAlertResult = (showAppointments: boolean) => {
    if (showAppointments) {
      this.requestCalendarAccess();
    }

    this.setState(state => ({
      ...state,
      alertsContext: {
        ...state.alertsContext,
        enableShowAppointmentsAlert: false
      }
    }));
  };

  private handleShowAppointmentsDrawerAlertResult = (
    showAppointments: boolean
  ) => {
    if (showAppointments) {
      this.requestCalendarAccess();
    }
  };

  private handleMonthAppointmentPayloadResponse = (
    monthAppointmentsPayload: IMonthAppointmentsPayload
  ) => {
    this.setState(state => {
      if (state.appointmentsMap.has(monthAppointmentsPayload.YearMonthString)) {
        const existingPayload = state.appointmentsMap.get(
          monthAppointmentsPayload.YearMonthString
        );
        if (existingPayload!.Hash === monthAppointmentsPayload.Hash) {
          log(
            `detected existing hash value so not updating for ${
              monthAppointmentsPayload.YearMonthString
            }`
          );
          return null; // don't update anything as nothing has changed
        }
      }

      const newMap = new Map(state.appointmentsMap);
      newMap.set(
        monthAppointmentsPayload.YearMonthString,
        monthAppointmentsPayload
      );

      const allCalendarsDisabled = !monthAppointmentsPayload.Calendars.some(
        c => !c.IsDisabled
      );

      return {
        ...state,
        inNoAppointmentsMode: false,
        appointmentsMap: newMap,
        areAllCalendarsDisabled: allCalendarsDisabled,
        calendarsContext: {
          ...state.calendarsContext,
          calendars: monthAppointmentsPayload.Calendars
        },
        alertsContext: {
          ...state.alertsContext,
          enableNoCalendarsDrawerAlert: allCalendarsDisabled
        }
      };
    });
  };

  private handleMenuClick = () => {
    this.setState({ ...this.state, isDrawerOpen: true });
  };

  private goToToday = () => {
    this.displayDate(new Date());
    this.handleSelectDate(new Date());
  };

  private handlePreviousMonthClicked = () => {
    this._carouselRef.current!.goPrevious();
  };

  private handleNextMonthClicked = () => {
    this._carouselRef.current!.goNext();
  };

  private handleSelectedDayChanged = (dayChange: number) => {
    if (dayChange !== 0) {
      const newSelectedDate = addDays(
        this.state.selectedDateContext.selectedDate,
        dayChange
      );

      this.setState(state => ({
        ...state,
        selectedDateContext: {
          ...state.selectedDateContext,
          selectedDate: newSelectedDate
        }
      }));
      if (!isSameMonth(this.currentDisplayDate, newSelectedDate)) {
        this.displayDate(newSelectedDate);
      }
    }
  };

  private displayDate = (
    date: Date,
    skipUpdatingCalendarGrid: boolean = false
  ) => {
    if (!skipUpdatingCalendarGrid) {
      this.setState(state => {
        return {
          ...state,
          slot1Date: date,
          slot2Date: addMonths(date, 1),
          slot3Date: addMonths(date, -1)
        };
      });
      this._carouselRef.current!.reset();
    }

    const urlParams = new URLSearchParams(this.props.location.search);
    urlParams.set(QueryStringNames.DISPLAYED_DATE, toYearMonthString(date));
    this.props.history.replace({ search: `?${urlParams}` });
  };

  private handleEventFromNative = event => {
    log(`handleEventFromNative - ${event.detail.name}`);

    const name = event.detail.name;
    if (name === InteropConstants.NATIVE_EVENT_POPOVERWILLSHOW_DETAILNAME) {
      const now = new Date();

      const updateSelected =
        this._popoverClosedTime &&
        differenceInMinutes(now, this._popoverClosedTime) > 30;
      const updateToday = !isSameDay(this.state.selectedDateContext.today, now);

      if (updateToday || updateSelected) {
        this.setState(state => ({
          ...state,
          selectedDateContext: {
            ...state.selectedDateContext,
            today: updateToday ? now : state.selectedDateContext.today,
            selectedDate: updateSelected
              ? now
              : state.selectedDateContext.selectedDate
          }
        }));
      }
    } else if (
      name === InteropConstants.NATIVE_EVENT_POPOVERWILLCLOSE_DETAILNAME
    ) {
      // note the time we closed
      this._popoverClosedTime = new Date();
    } else if (
      name === InteropConstants.NATIVE_EVENT_CALENDAR_PERMISSION_STATUS
    ) {
      const status = event.detail.returnString;
      let showAlert = false;
      let showDrawerAlert = true;
      let inNoAppointmentsMode = true;
      switch (status) {
        case PermissionStatus.Allowed:
          this.fetchCurrentMonthAppointments();
          showDrawerAlert = false;
          showAlert = false;
          inNoAppointmentsMode = false;
          break;
        case PermissionStatus.NotDetermined:
          showDrawerAlert = true;
          showAlert = true;
          inNoAppointmentsMode = true;
          break;
        case PermissionStatus.Denied:
        default:
          showAlert = false;
          showDrawerAlert = true;
          inNoAppointmentsMode = true;
          break;
      }
      this.setState(state => ({
        ...state,
        inNoAppointmentsMode,
        alertsContext: {
          ...state.alertsContext,
          enableShowAppointmentsAlert: showAlert,
          enableShowAppointmentsDrawerAlert: showDrawerAlert
        },
        calendarPermissionStatus: status
      }));
    }
  };

  private handleEventWithDataFromNative = event => {
    const payload: INativeEventPayload = event.detail;

    log(`handleEventWithDataFromNative - ${payload.name}`);

    if (
      payload.name ===
      InteropConstants.NATIVE_EVENT_MONTHAPPOINTMENTS_DETAILNAME
    ) {
      // if we got appointments back just make sure we aren't showing
      // the alert about adding appointments anymore
      if (
        this.state.alertsContext.enableShowAppointmentsAlert ||
        this.state.alertsContext.enableShowAppointmentsDrawerAlert ||
        this.state.calendarPermissionStatus !== PermissionStatus.Allowed
      ) {
        this.setState(state => ({
          ...state,
          alertsContext: {
            ...state.alertsContext,
            enableShowAppointmentsAlert: false,
            enableShowAppointmentsDrawerAlert: false
          },
          calendarPermissionStatus: PermissionStatus.Allowed,
          inNoAppointmentsMode: false
        }));
      }

      const monthAppointmentsPayload: IMonthAppointmentsPayload = JSON.parse(
        payload.data
      );
      this.setState(state => {
        if (
          state.appointmentsMap.has(monthAppointmentsPayload.YearMonthString)
        ) {
          const existingPayload = state.appointmentsMap.get(
            monthAppointmentsPayload.YearMonthString
          );
          if (existingPayload!.Hash === monthAppointmentsPayload.Hash) {
            log(
              `detected existing hash value so not updating for ${
                monthAppointmentsPayload.YearMonthString
              }`
            );
            return null; // don't update anything as nothing has changed
          }
        }

        const newMap = new Map(state.appointmentsMap);
        newMap.set(
          monthAppointmentsPayload.YearMonthString,
          monthAppointmentsPayload
        );

        const allCalendarsDisabled = !monthAppointmentsPayload.Calendars.some(
          c => !c.IsDisabled
        );

        return {
          ...state,
          appointmentsMap: newMap,
          calendarsContext: {
            ...state.calendarsContext,
            calendars: monthAppointmentsPayload.Calendars
          },
          inNoAppointmentsMode: false,
          areAllCalendarsDisabled: allCalendarsDisabled,
          alertsContext: {
            ...state.alertsContext,
            enableNoCalendarsDrawerAlert: allCalendarsDisabled
          }
        };
      });

      this.primeOtherMonths();
    }
  };

  private fetchCurrentMonthAppointments = async (): Promise<void> => {
    if (getIsInMacWebview()) {
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        `${InteropConstants.JS_MESSAGE_GETMONTHAPPOINTMENTS}${
          InteropConstants.MESSAGE_DELIMITER
        }${toYearMonthString(this.currentDisplayDate)}`
      );
    } else {
      getAppointmentsForMonth(
        this.currentDisplayDate.getFullYear(),
        this.currentDisplayDate.getMonth()
      ).then(this.handleMonthAppointmentPayloadResponse);
    }
  };

  private requestCalendarAccess = () => {
    if (getIsInMacWebview()) {
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        InteropConstants.JS_MESSAGE_REQUEST_CALENDAR_PERMISSION
      );
    }
  };

  private primeOtherMonths = () => {
    const date = this.currentDisplayDate;
    // for this first month we are showing go ahead and prime the previous 2
    // months and the next 3 months
    if (getIsInMacWebview()) {
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        `${InteropConstants.JS_MESSAGE_GETMONTHAPPOINTMENTS}${
          InteropConstants.MESSAGE_DELIMITER
        }${toYearMonthString(addMonths(date, 1))}`
      );
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        `${InteropConstants.JS_MESSAGE_GETMONTHAPPOINTMENTS}${
          InteropConstants.MESSAGE_DELIMITER
        }${toYearMonthString(addMonths(date, -1))}`
      );
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        `${InteropConstants.JS_MESSAGE_GETMONTHAPPOINTMENTS}${
          InteropConstants.MESSAGE_DELIMITER
        }${toYearMonthString(addMonths(date, -2))}`
      );
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        `${InteropConstants.JS_MESSAGE_GETMONTHAPPOINTMENTS}${
          InteropConstants.MESSAGE_DELIMITER
        }${toYearMonthString(addMonths(date, 2))}`
      );
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        `${InteropConstants.JS_MESSAGE_GETMONTHAPPOINTMENTS}${
          InteropConstants.MESSAGE_DELIMITER
        }${toYearMonthString(addMonths(date, 3))}`
      );
    } else {
      // getMonthAppointments(toYearMonthString(addMonths(date, 1))).then(
      //   this.handleMonthAppointmentPayloadResponse
      // );
      // getMonthAppointments(toYearMonthString(addMonths(date, -1))).then(
      //   this.handleMonthAppointmentPayloadResponse
      // );
      // getMonthAppointments(toYearMonthString(addMonths(date, -2))).then(
      //   this.handleMonthAppointmentPayloadResponse
      // );
      // getMonthAppointments(toYearMonthString(addMonths(date, 2))).then(
      //   this.handleMonthAppointmentPayloadResponse
      // );
      // getMonthAppointments(toYearMonthString(addMonths(date, 3))).then(
      //   this.handleMonthAppointmentPayloadResponse
      // );
    }
  };
}

export default withRouter(MonthCalendar);
